/* CurrentUser component styles */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Fira Sans", sans-serif;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
}

h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
}
/* CurrentUser component styles */

h3 {
  font-size: 24px;
  color: #333;
}

p {
  font-size: 18px;
  color: rgb(8, 111, 8);
  font-weight: 700;

}
.state{
    color:blue;
    font-size: 18px;
    font-weight: bold;
 
    
}
.center{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

  