/* Basic Resets and Fonts */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Fira Sans", sans-serif;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
}

h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
}

/* Profile Page Styles */
.profile-container {
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 1rem;
    width: 100%;
    max-width: 1200px;
    overflow: hidden; /* Ensure no content overflows */
}

.profile-header {
    text-align: center;
    margin-bottom: 2rem;
}

.profile-header h1 {
    font-size: 2rem;
    color: #333;
}

.table-wrapper {
    overflow-x: auto; /* Allow horizontal scrolling if necessary */
    width: 100%;
}

.profile-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
    min-width: 600px; /* Ensure table has a minimum width for readability */
}

.profile-table th, .profile-table td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-table th {
    background-color: #f4f4f4;
    font-weight: 600;
}

.profile-table td {
    overflow-wrap: break-word; /* Ensure long words break correctly */
}

.profile-table th, .profile-table td {
    white-space: nowrap; /* Prevent text wrapping */
}

.profile-table td {
    max-width: 200px; /* Set a max width to ensure columns don't get too wide */
}

/* Ensure table headers and content are readable on small screens */
@media (max-width: 768px) {
    .profile-table {
        font-size: 0.875rem; /* Adjust font size for smaller screens */
    }

    .profile-table th, .profile-table td {
        padding: 0.5rem;
    }

    .profile-header h1 {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .profile-container {
        padding: 1rem;
        overflow-x: hidden; /* Prevent horizontal scrolling on very small screens */
    }

    .profile-header h1 {
        font-size: 1.25rem;
    }

    .profile-table {
        font-size: 0.75rem; /* Further adjust font size */
    }

    .profile-table th, .profile-table td {
        padding: 0.5rem;
    }
}
