/* Basic Resets and Fonts */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Fira Sans", sans-serif;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
}

h1, h2, h3, h4, h5, h6, label, span {
    font-weight: 500;
}


/* Option component styles */
.formfield {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
    border-radius: 10px; /* Rounded corners for a soft look */
    backdrop-filter: blur(10px); /* Blur the background */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    border: 2px solid black; /* Optional: border with slight transparency */
    padding: 20px; /* Space inside the element */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .inputfield {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid black;
    border-radius: 4px;
    font-size: 18px;
    background-color: transparent;
    color: black;
    
  }
  
  .inputfield:focus {
    border-color: #007bff;
    outline: none;
  }
  
  button.transparent-button {
    background: transparent;
    border: 2px solid #007bff;
    color: #007bff;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button.transparent-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 16px;
  }
  
  .phonenumber{
    color: black;

  }