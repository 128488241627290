@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

/* Basic Resets and Fonts */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Fira Sans", sans-serif;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

h1, h2, h3, h4, h5, h6, label, span {
  font-weight: 500;
}

/* Login Form Container */
.auth-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  min-height: 100vh;
}

/* Login Form Styles */
form.login, .login-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

form.login h3, .login-form h3 {
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
  color: #333;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.flex label, .login-form label {
  font-size: 0.875rem;
  color: #555;
  text-align: left;
}

.flex input.form-control, .login-form input {
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.flex input.form-control:focus, .login-form input:focus {
  border-color: #007bff;
  outline: none;
}

.d-grid, .login-form .d-grid {
  margin-top: 1rem;
}

button, .login-form button {
  background-color: #007bff;
  color: #fff;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

button:hover, .login-form button:hover {
  background-color: #0056b3;
}

p.forgot-password, .login-form .forgot-password {
  margin-top: 1rem;
  font-size: 0.875rem;
}

p.forgot-password a, .login-form .forgot-password a {
  color: #007bff;
  text-decoration: none;
}

p.forgot-password a:hover, .login-form .forgot-password a:hover {
  text-decoration: underline;
}

.continue-p {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.space {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.transparent-button {
  background: transparent;
  border: 2px solid #3498db;
  color: #3498db;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.transparent-button:hover {
  background: #3498db;
  color: #fff;
}

.transparent-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5);
}

/* Image Upload Page Styles */
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.flex-grow {
  flex-grow: 1;
}

.card {
  background-color: #f7f7f7;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-danger {
  background-color: #dc3545;
  color: #fff;
}

.btn-danger:hover {
  background-color: #c82333;
}

/* Responsive Styles */
@media (max-width: 768px) {
  form.login, .login-form {
    padding: 1.5rem;
  }

  form.login h3, .login-form h3 {
    font-size: 1.5rem;
  }

  .flex label, .login-form label {
    font-size: 0.75rem;
  }

  .flex input.form-control, .login-form input {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  button, .login-form button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  p.forgot-password, .login-form .forgot-password {
    font-size: 0.75rem;
  }

  .container {
    padding: 0.5rem;
  }

  .card {
    padding: 0.5rem;
  }

  .space {
    flex-direction: column;
    gap: 0.5rem;
  }

  .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  form.login, .login-form {
    padding: 1rem;
  }

  form.login h3, .login-form h3 {
    font-size: 1.25rem;
  }

  .flex label, .login-form label {
    font-size: 0.75rem;
  }

  .flex input.form-control, .login-form input {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  button, .login-form button {
    padding: 0.5rem;
    font-size: 0.875rem;
  }

  p.forgot-password, .login-form .forgot-password {
    font-size: 0.75rem;
  }
}
/* Basic Resets and Fonts */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Fira Sans", sans-serif;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

/* Form Container */
.form-container {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  width: 100%;
  max-width: 500px;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.register-form h3 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

/* Form Elements */
.form-control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.forgot-password {
  font-size: 0.875rem;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .form-container {
      padding: 1rem;
  }

  .register-form h3 {
      font-size: 1.5rem;
  }

  .form-control {
      font-size: 0.875rem;
  }

  .btn-primary {
      font-size: 0.875rem;
  }
}
