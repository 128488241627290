/* profile.css */

/* Basic Reset */
body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #ADADAD !important;
  }
  
  /* Container */
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #CACACA; /* Semi-transparent white background */
    border-radius: 10px; /* Rounded corners for a soft look */
    border: 10px solid white;
   
  }
  
  /* Flexbox Layout */
  .flex-grow {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Card */
  .card {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white background */
    border-radius: 10px; /* Rounded corners for a soft look */
    backdrop-filter: blur(10px); /* Blur the background */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: border with slight transparency */
    padding: 20px; /* Space inside the element */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Button Styles */
  .btn {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: white;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-danger {
    background-color: #dc3545;
    color: white;
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  
  /* Notification */
  .notification {
    margin-top: 20px;
    padding: 10px;
    background-color: #f8d7da;
    color: #721c24;
    border-radius: 4px;
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .container {
      padding: 10px;
    }
  
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  
    .card {
      padding: 15px;
    }
  }
  